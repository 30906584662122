@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Muli:wght@600&display=swap");
// Max screen sizes variables
$screen-xs-max: 768px; // < 768 (xsmall phones)
$screen-sm-max: 992px; // < 992px (small tablet)
$screen-md-max: 1200px; // < 1200 px (large desktops

// Mix screen sizes variables
$screen-xs-min: 768px; // < 768 (xsmall phones)
$screen-sm-min: 992px; // < 992px (small tablet)
$screen-md-min: 1200px; // < 1200 px (large desktops

/* Global Styles */
:root {
  --primary-color: #1471eb;
  --active-color: #abd1fc;
  --inactive-color: #f5f5f5;
  --dark-color: rgb(41, 41, 41);
  --light-color: #ffffff;
}
#app {
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
}
.color-primary {
  color: var(--primary-color);
}

.color-active {
  color: var(--active-color);
}

.color-light {
  color: var(--light-color);
}

.logo-large {
  max-width: 90%;
}

h1 {
  font-family: "Muli", sans-serif;
}

.App {
  text-align: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  background-color: var(--light-color);
  width: 100%;
}

.section1 {
  // Mobile first
  font-family: "Muli", sans-serif;
  display: flex;
  flex-direction: column-reverse;

  margin-top: 5%;
  margin-bottom: 5%;
  background-color: var(--light-color);
  // For the smallest non mobile device (non-mobile after)
  @media (min-width: $screen-sm-min) {
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}

.leftSection1 {
  //mobile
  height: 30%;
  text-align: center;
  display: flex;

  align-items: center;
  margin: 3%;
  width: auto;
  flex-direction: column-reverse;
  > .phone-frames > .android-phone {
    display: none;
  }
  > .phone-frames > .iphone {
    object-fit: contain;
    width: 70%;
    display: initial;
  }
  //Desktop
  @media (min-width: $screen-sm-min) {
    width: 50%;
    flex-direction: column;
    min-height: 700px;

    > .phone-frames > .phone {
      width: 50%;
      object-fit: contain;
      display: initial;
    }
    > .phone-frames > .iphone {
      object-fit: contain;
    }
  }
}

.rightSection1Box > button:hover {
  background-color: var(--active-color);
  cursor: pointer;
  color: black;
}
.rightSection1Box {
  text-align: center;
  width: 100%;
  margin-top: 3%;
  @media (min-width: $screen-sm-min) {
    width: 35%;
    height: 100%;
    margin-top: 0%;
    > {
      h1 {
        font-size: 100px;
        line-height: 0;
      }

      h2 {
        font-size: 40px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}

.section2 {
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: var(--inactive-color);
  padding-top: 5%;
  width: 100%;
  @media (min-width: $screen-sm-min) {
    flex-direction: row;
    height: 350px;
    padding-top: 2%;
    padding-bottom: 0%;
  }
}

.innerSectionBox {
  height: 50%;
  width: 100%;
  margin: 1% auto;
  padding-bottom: 10%;
  > img {
    width: auto;
    margin: 0 auto;
    height: 100px;
  }
  @media (min-width: $screen-sm-min) {
    > img {
      margin: 0 auto;
      height: 60px;
    }
  }
}

.section2-list {
  font-family: "Muli", sans-serif;
  color: var(--light-color);
  list-style: none;
  margin: 2%;
  padding: 0;
  margin: 0 auto;
  margin-top: 20px;
  width: 65%;
  @media (min-width: $screen-sm-min) {
    font-size: 20px;
    width: 80%;
    text-align: left;
  }
}

.section2-list > li {
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.section3 {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 10%;
  background-color: var(--dark-color);
  @media (min-width: $screen-sm-min) {
    height: 700px;
    max-height: 80vh;
    padding-top: 1.3%;
    padding-bottom: 1%;
    flex-direction: row;
  }
}

.leftSection3 {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: $screen-sm-min) {
    float: right;
    height: 50%;
    width: 45%;
  }
}

.rightSection3 {
  width: 100%;
  margin: 1% auto auto auto;
  justify-content: space-between;
  @media (min-width: $screen-sm-min) {
    width: 40%;
    height: 60%;
    text-align: left;
    margin-top: 8%;
  }

  > h2 {
    font-size: 40px;
    color: white;
    cursor: pointer;
  }
}

.step-header {
  font-family: "Muli", sans-serif;
  font-size: 15px;
  cursor: pointer;

  @media (min-width: $screen-sm-min) {
    font-weight: 300;
    font-size: 20px;
  }
}

// Not main section scss
li {
  padding-bottom: 2.5%;
  color: #3a3a3a;
}

.phone-frames {
  height: 70%;
  display: flex;
  justify-content: center;
  @media (min-width: $screen-sm-min) {
    width: 70%;
  }
}

.iphone {
  margin-left: 2%;
}

.phoneframe2 {
  height: 100%;
  width: 100%;
  > img {
    height: 500px;
  }
  @media (min-width: $screen-sm-min) {
    > img {
      margin-top: 10%;
      float: right;
    }
  }
}

.ul-title {
  font-size: 2rem;
  font-family: "Muli", sans-serif;
  color: var(--dark-color);
}

.formTop {
  display: inline;

  > input {
    width: 48%;
    height: 25px;
    padding: 5px;
    font-size: 16px;
  }
}

.input1 {
  float: left;
}

.input2 {
  float: right;
}

.stores {
  width: 80%;
  margin-top: 1%;
  > a img {
    height: 40px;
    margin: 3%;
  }
  @media (min-width: $screen-sm-min) {
    display: inline-flex;
    width: 70%;
    justify-content: space-around;

    > a img {
      height: 50px;
      margin-top: 1%;
    }
  }
}

.headline-h2-section3 {
  font-family: "Muli", sans-serif;
  color: var(--light-color);
  align-self: center;
  margin: 0;
  padding-top: 10%;
  font-size: 20px;
  @media (min-width: $screen-sm-min) {
    font-size: 45px;
  }
}

.headline-p {
  font-weight: 400;
  font-size: 1.3em;
  width: 90%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 5%;
  color: #3a3a3a;
  @media (min-width: $screen-sm-min) {
    font-family: "Montserrat";
    font-size: 1.3em;
    width: 90%;
  }
}

.headline-h {
  font-size: 1.8em;
}

.container {
  position: relative;
  padding-bottom: 2.5rem;
}
.footer {
  border-top: 0.5px solid #3a3a3a;
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100vw;
  background-color: var(--light-color);
  font-family: "Muli", sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 2.5rem;
  @media (max-width: $screen-sm-min) {
    font-size: 10px;
    img {
      display: none;
    }
  }

  &__link {
    color: var(--dark-color);
    margin: 1rem;
    font-size: 1.3em;
    text-decoration: none;
  }
}

.primary-btn-section1 {
  width: 55%;
  margin: 0 auto;
  max-width: 170px;
  padding: 10px;
  border: none;
  background-color: var(--primary-color);
  color: var(--light-color);
  font-size: 18px;
  font-family: "Muli", sans-serif;
  letter-spacing: 0.1em;
  border-radius: 5px;
  cursor: pointer;
  justify-self: center;

  @media (min-width: $screen-sm-min) {
    width: 200px;
  }
}

.secondary-btn {
  margin: 1%;
  min-width: 150px;
  padding: 10px;
  border-width: 1px;
  border-color: var(--primary-color);
  background-color: var(--light-color);
  color: black;
  font-size: 18px;
  font-family: "Muli", sans-serif;
  letter-spacing: 0.1em;
  border-radius: 5px;
  justify-self: center;
  @media (min-width: $screen-sm-min) {
    width: 25%;
    margin: 0 auto auto 2%;
  }
}

.custom-text-container {
  padding: 2%;
}

.title {
  text-align: center;
  font-size: 36px;
  font-weight: bolder;
}

.subtitle {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.form-group {
  text-align: center;
}

.submit-button {
  width: 10%;
  padding: 1%;
  font-size: 24px;
  border-radius: 8px;
  background-color: var(--active-color);
  border: none;
}

.input-label {
  font-size: 18px;
  font-weight: bold;
}

.form-input,
.form-input-small,
.form-textarea {
  width: 20%;
  border: none;
  border-radius: 8px;
  margin: 0.25%;
  padding: 0.5% 0%;
}

.form-input-small {
  font-size: x-small;
}

.form-input,
.form-input-small {
  text-align: center;
}
form {
  text-align: left;
}

.thankyou-container {
  height: 100vh;
}

.question-table {
  height: 100%;
}
